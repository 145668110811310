import { useState } from "react"
import FullCalender from "@fullcalendar/react"
import { formatDate } from "@fullcalendar/core"
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import interactionPlugin from "@fullcalendar/interaction" // needed for dayClick
import timeGridPlugin from "@fullcalendar/timegrid"
import listPlugin from "@fullcalendar/list"
import {
    Box,
    ListItem,
    ListItemText,
    List,
    Typography,
    useTheme
} from "@mui/material"

import Header from "../../components/Header"
import { tokens } from "../../theme"



const Calender=()=>{
    const theme=useTheme()
    const colors=tokens(theme.palette.mode)
    const [currentEvents,setCurrentEvents]=useState([])

    const handleDateClick=(selected)=>{
        const title=prompt("Please enter a new title for your events")
        const calenderApi=selected.view.calender
        calenderApi.unselect()
        if(title){
            calenderApi.addEvent({
                id:`${selected.dateStr}-${title}`,
                title,
                start:selected.startStr,
                end:selected.endStr,
                allDay:selected.allDay
            })
        }
    }
    const handleEventClick=(selected)=>{
        if(window.confirm(`Are you sure you want to delete the event ${selected.event.title}`)){
              selected.event.remove()
        }
    }
    return (
        <Box m="20px">
          <Header title="CALENDAR" subtitle="Full calendar Interactive Page"/>
          <Box display="flex" justifyContent="space-between">
              {/* CALENDER SIDEBAR */}
              <Box flex="1 1 20%" backgroundColor={colors.primary[400]} borderRadius="4px" p="15px">
                 <Typography variant="h5">Events</Typography>
                 <List>
                    {currentEvents.map(event=>(
                        <ListItem key={event.id} sx={{backgroundColor:colors.greenAccent[500],margin:"10px 0",borderRadius:"2px"}}>
                           <ListItemText
                           primary={event.title}
                           secondary={
                                <Typography>
                                    {formatDate(event.start,{
                                        year:"numeric",
                                        month:"short",
                                        day:"numeric"
                                    })}
                                </Typography>
                           }
                           />
                        </ListItem>
                    ))}
                 </List>
              </Box>
              {/* CALENDER */}
              <Box flex="1 1 100%" ml="15px">
                 <FullCalender
                 height="70vh"
                 plugins={[
                    dayGridPlugin,
                    timeGridPlugin,
                    interactionPlugin,
                    listPlugin
                 ]
                 }
                 headerToolbar={{
                    left:"prev,next today",
                    center:"title",
                    right:"dayGridMonth,timeGridWeek,timeGridDay,listMonth"
                 }}
                 initialView="dayGridMonth"
                 editable={true}
                 selectable={true}
                 selectMirror={true}
                 dayMaxEvents={true}
                 select={handleDateClick}
                 eventClick={handleEventClick}
                 eventsSet={(events)=>setCurrentEvents([...events])}
                 initialEvents={
                    [
                        {id:"1234",title:"All-day event",date:"2022-09-14"},
                        {id:"4321",title:"Timed event",date:"2022-09-28"}
                    ]
                 }
                 />
              </Box>
          </Box>
        </Box>
    )
}

export default Calender